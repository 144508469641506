/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

import '@/assets/styles/layout.scss'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
// import 'primevue/resources/themes/lara-light-indigo/theme.css'
// import 'primevue/resources/themes/saga-blue/theme.css'
// import 'primevue/resources/themes/rhea/theme.css'

import AutoComplete from 'primevue/autocomplete'
import Badge from 'primevue/badge'
import Breadcrumb from 'primevue/breadcrumb'
import BlockUI from 'primevue/blockui'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Column from 'primevue/column'
import ConfirmPopup from 'primevue/confirmpopup'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import InlineMessage from 'primevue/inlinemessage'
import Listbox from 'primevue/listbox'
import Menu from 'primevue/menu'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import Password from 'primevue/password'
import ProgressSpinner from 'primevue/progressspinner'
import SelectButton from 'primevue/selectbutton'
import Skeleton from 'primevue/skeleton'
import SplitButton from 'primevue/splitbutton'
import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar'
import InputMask from 'primevue/inputmask'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import PickList from 'primevue/picklist'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'
import FileUpload from 'primevue/fileupload'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import ProgressBar from 'primevue/progressbar'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import TreeTable from 'primevue/treetable'

import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import OrderList from 'primevue/orderlist'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

Vue.directive('styleclass', StyleClass)
Vue.directive('Ripple', Ripple)
Vue.directive('tooltip', Tooltip)

Vue.component('AutoComplete', AutoComplete)
Vue.component('Badge', Badge)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('BlockUI', BlockUI)
Vue.component('Button', Button)
Vue.component('Calendar', Calendar)
Vue.component('Card', Card)
Vue.component('Chart', Chart)
Vue.component('Checkbox', Checkbox)
Vue.component('Chip', Chip)
Vue.component('Column', Column)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('ConfirmPopup', ConfirmPopup)
Vue.component('DataTable', DataTable)
Vue.component('Dialog', Dialog)
Vue.component('Divider', Divider)
Vue.component('Dropdown', Dropdown)
Vue.component('InputNumber', InputNumber)
Vue.component('InputText', InputText)
Vue.component('InputSwitch', InputSwitch)
Vue.component('InlineMessage', InlineMessage)
Vue.component('Listbox', Listbox)
Vue.component('Menu', Menu)
Vue.component('Message', Message)
Vue.component('MultiSelect', MultiSelect)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Paginator', Paginator)
Vue.component('Panel', Panel)
Vue.component('Password', Password)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('SelectButton', SelectButton)
Vue.component('Skeleton', Skeleton)
Vue.component('SplitButton', SplitButton)
Vue.component('TabMenu', TabMenu)
Vue.component('Tag', Tag)
Vue.component('Textarea', Textarea)
Vue.component('Toast', Toast)
Vue.component('ToggleButton', ToggleButton)
Vue.component('Toolbar', Toolbar)
Vue.component('Tooltip', Tooltip)
Vue.component('InputMask', InputMask)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('PickList', PickList)
Vue.component('FileUpload', FileUpload)
Vue.component('ProgressBar', ProgressBar)
Vue.component('TreeTable', TreeTable)
Vue.component('Splitter', Splitter)
Vue.component('SplitterPanel', SplitterPanel)
Vue.component('OrderList', OrderList)

Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)

Vue.use(PrimeVue, {
  locale: {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'É igual a',
    notEquals: 'Não é igual',
    noFilter: 'Sem filtro',
    lt: 'Menor que',
    lte: 'Menos que ou igual a',
    gt: 'Maior que',
    gte: 'Melhor que ou igual a',
    dateIs: 'A data é',
    dateIsNot: 'A data não é',
    dateBefore: 'A data é anterio',
    dateAfter: 'A data é depois',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Corresponder a todos',
    matchAny: 'Corresponder a Qualquer',
    addRule: 'Adicionar regra',
    removeRule: 'Remover regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Upload',
    cancel: 'Cancelar',
    dayNames: [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Terç', 'Quart', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesMin: ['Do', 'Seg', 'Te', 'Qua', 'Qui', 'Sex', 'Sá'],
    monthNames: [
      'Janeiro',
      'Fervereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fer',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    weekHeader: 'CB',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Insira uma senha',
    emptyFilterMessage: 'Nenhum resultado encontrado',
    emptyMessage: 'Nenhuma opção disponível',
  },
})
Vue.use(ToastService)
Vue.use(ConfirmationService)
