export default [
  {
    path: '/login',
    component: () => import('@/views/gestao/login/index'),
    meta: {
      gestaoEntidade: true,
      body: 'bg-light',
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/gestao/login/login'),
      },
      {
        path: 'password_confirmation',
        name: 'password-confirmation',
        component: () => import('@/views/gestao/login/password-confirmation'),
      },
      {
        path: 'password-forgot',
        name: 'password-forgot',
        component: () => import('@/views/gestao/login/password-forgot'),
      },
    ],
  },
]
