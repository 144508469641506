export default [
    {
      path: '/login',
      component: () => import('@/views/consignataria/login/index'),
      meta: {
        body: 'bg-light',
      },
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('@/views/consignataria/login/login'),
        },
        {
          path: 'password_confirmation',
          name: 'password-confirmation',
          component: () => import('@/views/consignataria/login/password-confirmation'),
        },
        {
          path: 'password-forgot',
          name: 'password-forgot',
          component: () => import('@/views/consignataria/login/password-forgot'),
        },
      ],
    },
  ]
  