export default [
  {
    path: '/',
    component: () => import('@/views/consignataria/App.vue'),
    meta: {
      auth: true,
    },
    children: [
      // {
      //   path: '',
      //   name: 'dashboard',
      //   component: () => import('@/views/consignataria/home/HomeDashboard.vue'),
      // },
      {
        path: '',
        name: 'servidores',
        component: () =>
          import('@/views/consignataria/servidor/ServidorIndex.vue'),
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/consignataria/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/consignataria/servidor/ServidorMenu.vue'),
            children: [
              {
                path: '',
                name: 'servidores-margem',
                component: () =>
                  import('@/views/consignataria/servidor/ServidorMargem.vue'),
              },
              {
                path: 'consignacoes',
                name: 'consignacoes',
                component: () =>
                  import(
                    '@/views/consignataria/consignacoes/ConsignacaoList.vue'
                  ),
              },
              {
                path: 'consignacao',
                name: 'consignacao',
                component: () =>
                  import(
                    '@/views/consignataria/consignacoes/ConsignacaoForm.vue'
                  ),
              },
              {
                path: 'cancelar-consignacao/:idConsignacao',
                name: 'cancelar-consignacao',
                component: () =>
                  import(
                    '@/views/consignataria/consignacoes/ConsignacaoCancelar.vue'
                  ),
              },
              {
                path: 'renegociacoes',
                name: 'renegociacoes',
                component: () =>
                  import(
                    '@/views/consignataria/renegociacao/RenegociacaoList.vue'
                  ),
              },
              {
                path: 'compra-dividas',
                name: 'compra-divida-lista',
                component: () =>
                  import(
                    '@/views/consignataria/compraDivida/CompraDividaList.vue'
                  ),
              },
              {
                path: 'reserva-cartao-lista',
                name: 'reserva-cartao-lista',
                component: () =>
                  import(
                    '@/views/consignataria/reservaCartao/ReservaCartaoList.vue'
                  ),
              },
              {
                path: 'propostas',
                name: 'propostas',
                component: () =>
                  import('@/views/consignataria/propostas/PropostaList.vue'),
              },
              {
                path: 'compra-divida',
                name: 'compra-divida',
                component: () =>
                  import(
                    '@/views/consignataria/compraDivida/CompraDividaForm.vue'
                  ),
              },
              {
                path: 'consignacao-online',
                name: 'consignacao-online',
                component: () =>
                  import('@/views/consignataria/propostas/PropostaForm.vue'),
              },
              {
                path: 'renegociar',
                name: 'renegociar',
                component: () =>
                  import(
                    '@/views/consignataria/renegociacao/RenegociarForm.vue'
                  ),
              },
              {
                path: 'renegociar-online',
                name: 'renegociar-online',
                component: () =>
                  import(
                    '@/views/consignataria/renegociacao/RenegociarOnline.vue'
                  ),
              },
              {
                path: 'nova-reserva-cartao',
                name: 'nova-reserva-cartao',
                component: () =>
                  import(
                    '@/views/consignataria/reservaCartao/ReservaCartaoForm.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/consignataria/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/consignataria/consignacoes/ConsignacaoMenu.vue'),
            children: [
              {
                path: 'consignacoes/:idconsig',
                name: 'consignacao-detalhe',
                component: () =>
                  import(
                    '@/views/consignataria/consignacoes/ConsignacaoDetalhe.vue'
                  ),
              },
              {
                path: 'consignacoes/:idconsig/parcelas',
                name: 'consignacao-parcelas',
                component: () =>
                  import(
                    '@/views/consignataria/consignacoes/ConsignacaoParcelas.vue'
                  ),
              },
              // {
              //   path: 'consignacoes/:idconsig/comprovantes',
              //   name: 'consignacao-comprovantes',
              //   component: () =>
              //     import(
              //       '@/views/consignataria/consignacoes/ConsignacaoComprovantes.vue'
              //     ),
              // },
              // {
              //   path: 'consignacoes/:idconsig/historico',
              //   name: 'consignacao-historico',
              //   component: () =>
              //     import(
              //       '@/views/consignataria/consignacoes/ConsignacaoHistorico.vue'
              //     ),
              // },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/consignataria/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/consignataria/renegociacao/RenegociacaoMenu.vue'),
            children: [
              {
                path: 'renegociacoes/:idconsig',
                name: 'renegociacao-consignacao-detalhe',
                component: () =>
                  import(
                    '@/views/consignataria/renegociacao/RenegociacaoDetalhe.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/consignataria/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/consignataria/compraDivida/CompraDividaMenu.vue'),
            children: [
              {
                path: 'compra-dividas/:idcompradivida/:tipo',
                name: 'compra-divida-detalhe',
                component: () =>
                  import(
                    '@/views/consignataria/compraDivida/CompraDividaDetalhe.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/consignataria/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/consignataria/propostas/PropostaMenu.vue'),
            children: [
              {
                path: 'propostas/:idproposta',
                name: 'proposta-detalhe',
                component: () =>
                  import('@/views/consignataria/propostas/PropostaDetalhe.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'importar-arquivo',
        name: 'importar-arquivo',
        component: () =>
          import(
            '@/views/consignataria/arquivo/importacao/ImportarArquivo.vue'
          ),
      },
      // {
      //   path: 'configuracao-perfil',
      //   name: 'configuracao-perfil',
      //   component: () =>
      //     import(
      //       '@/views/consignataria/configuracaoPerfil/ConfiguracaoPerfilUsuario.vue'
      //     ),
      // },
      // {
      //   path: 'configuracao-perfil/new',
      //   name: 'configuracao-perfil-new',
      //   component: () =>
      //     import(
      //       '@/views/consignataria/configuracaoPerfil/ConfiguracaoPerfilUsuarioForm.vue'
      //     ),
      // },
      // {
      //   path: 'configuracao-perfil/:id',
      //   name: 'configuracao-perfil-edit',
      //   component: () =>
      //     import(
      //       '@/views/consignataria/configuracaoPerfil/ConfiguracaoPerfilUsuarioForm.vue'
      //     ),
      // },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: () =>
          import('@/views/consignataria/usuarios/UsuarioLista.vue'),
        meta: {
          master: true,
        },
      },
      {
        path: 'relatorio',
        name: 'relatorio',
        component: () =>
          import('@/views/consignataria/relatorio/Relatorio.vue'),
      },
    ],
  },
]
